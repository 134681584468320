<template>
  <a-card>
    <div class="assign_title">
      <h3>客资未分配自动流入公海</h3>
      <div class="popover_con">
        <a-popover placement="top">
          <template #content>
            <p class="ls-content">决定长时间未分配机构的客资是否自动流入公海，已成单的客户不受影响</p>
          </template>
          <question-circle-outlined class="ls-icon" />
        </a-popover>
      </div>
    </div>
    <a-radio-group v-model:value="automaticInflow">
      <a-radio :value="false" style="margin-right: 40px;">否</a-radio>
      <a-radio :value="true">是</a-radio>
    </a-radio-group>
    <div v-if="automaticInflow" style="color: #000000; margin-top: 40px;">
      <span style="color: #000000;">超过</span>
      <a-input v-model:value="days" placeholder="" style="width: 60px; margin: 0 10px;" />
      <span style="color: #000000;">天未分配机构</span>
    </div>
    <div style="margin-top: 40px;">
      <a-button type="primary" @click="save">保存</a-button>
    </div>
  </a-card>
</template>
<script>
import { get, post } from "@/utils/http";
export default {
  name: 'CrmIndex',
  data() {
    return {
      automaticInflow: false,
      days: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: { 
    async getData() {
      try {
        let res = await get("/system/automaticLoss/list");
        if (res.code === '200') {
          this.automaticInflow = res.automaticInflow;
          this.days = res.days
        }
      } catch (error) {
        this.$message.error("请求失败")
      }
    },

    async save() {
      let reg = new RegExp(/(^[1-9]\d{0,2}$)/);
      if (this.automaticInflow) {
        if (reg.test(this.days) == false) {
          this.$message.error("请输入999以内的正整数");
          return
        };
      }
      try {
        let res = await post("/system/automaticLoss/edit", {
          automaticInflow: this.automaticInflow,
          days: this.automaticInflow ? this.days : 0
        });
        if (res.code === '200') {
          this.$message.success("保存成功");
        }
      } catch (error) {
        this.$message.error("请求失败")
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.assign_title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 30px;
    margin-bottom: 40px;

    .popover_con {
        padding-left: 5px;
    }
}

</style>